



































































































import Vue from 'vue'
import { mapState } from 'vuex'
import { Project } from '@/interfaces'

interface Rubrique {
  children?: Rubrique
  label: string
  value: null | string
}

export default Vue.extend({
  props: {
    project: {
      type: Object as () => Project,
      required: true,
      default: () => {
        return {
          id: null,
        }
      },
    },
    items: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      busy: false,
      viewportWidth: 0,
      formModel: {
        rubriqueKey: [null] as unknown,
        place: null as null | string,
        location: null as null | string,
      },
      deleteRubrique: false,
      deletePlace: false,
      deleteLocation: false,
      rubriques: [] as Rubrique[],
    }
  },
  computed: {
    ...mapState(['user']),
  },
  mounted() {
    if (this.user) {
      this.rubriques = this.user.client.config.rubriques
      this.rubriques.push({
        value: null,
        label: 'Pas de changement',
      })
    }

    this.viewportWidth = window.innerWidth

    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.viewportWidth = window.innerWidth
    },
    closeModal() {
      this.formModel = {
        rubriqueKey: [null],
        place: null,
        location: null,
      }

      this.deleteRubrique = false
      this.deletePlace = false
      this.deleteLocation = false

      this.$emit('closeEditModal')
    },
    submit() {
      let data = {
        ...this.formModel,
      }

      // Place
      if (this.deletePlace == true) {
        data.place = 'NULL'
      } else {
        data.place = this.formModel.place
      }

      // Location
      if (this.deleteLocation == true) {
        data.location = 'NULL'
      } else {
        data.location = this.formModel.location
      }

      // Rubrique
      if (this.deleteRubrique == true) {
        data.rubriqueKey = 'NULL'
      } else {
        if (Array.isArray(this.formModel.rubriqueKey)) {
          data.rubriqueKey = this.formModel.rubriqueKey[this.formModel.rubriqueKey.length - 1]
        } else {
          data.rubriqueKey = this.formModel.rubriqueKey
        }
      }

      this.busy = true
      this.$api
        .put(`/inventory/${this.project.id}/items/edit`, {
          items: this.items,
          data,
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Succès',
            message: 'Opération réalisée avec succès !',
          })

          this.closeModal()
        })
        .finally(() => {
          this.busy = false
        })
    },
    filterCategory(node: Record<string, string>, keyword: string) {
      return node.label.toLowerCase().includes(keyword.toLowerCase())
    },
  },
})
