


































































































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import { mapState } from 'vuex'
import InventoryForm from '@/components/InventoryForm.vue'
import InventorySynthesis from '@/components/Synthesis.vue'
import InventoryBilling from '@/components/InventoryBilling.vue'
import InventoryDocuments from '@/components/Documents.vue'
import InventoryItems from '@/views/inventoryItems/List.vue'
import { ApiResponse, Project, Document, Inventory, InventoryItem } from '@/interfaces'

interface Rubrique {
  children?: Rubrique[]
  label: string
  value: string
}

interface Badge {
  color: string
  value: string
}

interface TabObject {
  _props: Record<string, string>
}

export default Vue.extend({
  components: { Nav, InventoryForm, InventoryItems, InventorySynthesis, InventoryDocuments, InventoryBilling },
  data() {
    return {
      displayAlert: false,
      project: {} as Project,
      busy: false,
      customColors: [
        { color: '#48C989', percentage: 25 },
        { color: '#F7AD57', percentage: 50 },
        { color: '#E8584F', percentage: 75 },
        { color: '#263238', percentage: 100 },
      ],
      saveInventoryTrigger: false,
      structureDuePeriod: 0,
      activeTab: 'infos',
      rubriques: [],
      projectId: null as null | string,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    user(newVal) {
      this.rubriques = newVal.client.config.rubriques
    },
  },
  mounted() {
    this.initProject()

    if (this.$route.query.tab && this.$route.query.tab !== null) {
      this.activeTab = this.$route.query.tab as string
    }

    this.rubriques = this.user?.client?.config?.rubriques
  },
  methods: {
    initProject() {
      if (!this.projectId || this.projectId === null) {
        this.$api.get(`/inventory/${this.$route.params.inventoryId}/project`).then((response) => {
          const apiResponse = response.data as ApiResponse

          this.projectId = apiResponse.data.id

          this.refreshProject()
        })
      }
    },
    refreshProject() {
      if (this.projectId) {
        this.busy = true
        this.displayAlert = false

        this.$api
          .get(`/project/${this.projectId}`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.project = apiResponse.data
            this.structureDuePeriod = this.project.structure?.duePeriod ?? 0
          })
          .catch(() => {
            this.displayAlert = true
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    inventoryCreated(project: Project) {
      if (project.id) {
        this.$router.push({
          name: 'inventory_edit',
          params: {
            projectId: project.id.toString(),
            inventoryId: project.inventory?.id ? project.inventory.id.toString() : '',
          },
        })
      }
    },
    updateInventory(inventory: Inventory) {
      this.project.inventory = inventory
    },
    updateProject(project: Project) {
      this.project = project
    },
    getNumItems(project: Project): string {
      if (project.inventory) {
        return `Lots (${project.inventory.numItems})`
      } else {
        return 'Lots (?)'
      }
    },
    getDebiteurFormname(project: Project) {
      let debiteur = project.inventory?.debiteur?.formName ?? ''
      if (debiteur != '') {
        return '- ' + debiteur
      } else {
        return ''
      }
    },
    getProjectType(project: Project) {
      switch (project.structure?.type) {
        case 'volontaire':
          return 'is-volontary'

        case 'judiciaire':
          return 'is-judiciary'

        default:
          return 'is-unknown'
      }
    },
    getInventoryLabel(label: string) {
      switch (label) {
        case 'label1':
          return this.project.inventory?.value1Label

        case 'label2':
          return this.project.inventory?.value2Label
      }
    },
    deleteProject(project: Project) {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir supprimer cet inventaire ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .delete(`/project/${project.id}`)
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })

            this.$router.push({ name: 'inventories' })
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
            })
          })
      })
    },
    getStatusBadge(project: Project): Badge {
      switch (project.status) {
        case 'en_cours':
          return {
            color: '#EFE6FD',
            value: 'En cours',
          }

        case 'clos':
          return {
            color: '#EFE6FD',
            value: 'Clos',
          }

        default:
          return {
            color: '#EFE6FD',
            value: '-',
          }
      }
    },

    getStructureColor(project: Project) {
      return project.structure?.color
    },

    getPercentage(project: Project): number {
      if (project.inventory?.timeLimit) {
        if (project.inventory.timeLimit > 0) {
          const percentage = project.inventory.timeLimit * 100

          return percentage >= 100 ? 100 : percentage
        }
      }

      return 0
    },
    updateDocuments(documents: Document[]) {
      ; (this.$refs.inventoryDocumentsChild as InstanceType<typeof InventoryDocuments>).refreshDocuments(documents)
    },
    checkJudiciary(project: Project): boolean {
      return project.structure?.type == 'judiciaire'
    },
    changeTab(tab: TabObject) {
      if (!this.busy) {
        this.activeTab = tab._props.name ?? 'infos'
        // Here we use pushState as using Vue's router would trigger a page reload
        history.pushState(null, '', `${this.$route.path}?tab=${this.activeTab}`)

        this.triggerSave()
      }
    },
    triggerSave() {
      this.saveInventoryTrigger = !this.saveInventoryTrigger

      return true
    },
    goBack() {
      this.triggerSave()
      this.$router.back()
    },
    exportAsCSV(project: Project) {
      this.$api.get(`/inventory/${project.inventory?.id}/items`).then((response) => {
        const apiResponse = response.data as ApiResponse
        const items = apiResponse.data

        let csvArray = []

        csvArray.push([
          '"ID"',
          '"N° Lot"',
          '"N° Etiquette"',
          '"Description"',
          '"Lieu"',
          '"Emplacement"',
          '"Rubrique"',
          '"Estimation 1"',
          '"Estimation 2"',
          '"Estimation 3"',
        ])

        if (items.length > 0) {
          items.forEach((item: InventoryItem) => {
            csvArray.push([
              `"${item.id}"`,
              `"${item.position ?? ''}"`,
              `"${item.sticker ?? ''}"`,
              `"${item.description ? item.description.replace(/"/g, '""') : ''}"`,
              `"${item.place ?? ''}"`,
              `"${item.location ?? ''}"`,
              `"${item.rubriqueKey ?? ''}"`,
              `"${item.value1 ?? ''}"`,
              `"${item.value2 ?? ''}"`,
              `"${item.value3 ?? ''}"`,
            ])
          })
        }

        let csvContent = 'data:text/csv;charset=utf-8,\uFEFF'

        csvArray.forEach(function (rowArray) {
          let row = rowArray.join(';')
          csvContent += row + '\r\n'
        })

        let encodedURI = encodeURI(csvContent)

        let downloadLink = document.createElement('a')
        downloadLink.href = encodedURI
        downloadLink.download = 'export-inventory.csv'

        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
      })
    },
    getRubriqueRec(rubriques: Rubrique[], str: string): string {
      let rub = '-'
      rubriques.forEach((rubrique: Rubrique) => {
        if (rubrique.value == str) {
          rub = rubrique.label
        }
        if (rubrique.children && rub == '-') {
          rub = this.getRubriqueRec(rubrique.children, str)
        }
      })

      return rub
    },
    addFile() {
      document.getElementById('inputInventoryImport')?.click()
    },
    updateInputFile(event: Event) {
      const inputFiles = (event.target as HTMLInputElement).files
      if (!inputFiles) {
        this.$notify({
          type: 'error',
          title: 'Erreur',
          message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
        })
        return
      }
      this.importInventory(inputFiles[0])
    },
    importInventory(file: File) {
      if (!file) return
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener('load', () => {
        if (reader.result) {
          this.busy = true

          const formData = new FormData()

          let filePicker = document.getElementById('inputInventoryImport') as HTMLInputElement

          formData.append('file', (filePicker.files as FileList)[0])

          this.$api
            .post(`/project/${this.projectId}/inventory/${this.$route.params.inventoryId}/import`, formData)
            .then((response) => {
              const apiResponse = response.data as ApiResponse

              this.project = apiResponse.data
              this.$notify({
                type: 'success',
                title: 'Succès',
                message: 'Import réalisé avec succès !',
              })
            })
            .catch((error) => {
              if (error.response?.status === 413) {
                this.$notify({
                  type: 'error',
                  title: 'Erreur',
                  message: 'Erreur : le fichier que vous souhaitez téléverser est trop lourd.',
                })
              } else {
                this.$notify({
                  type: 'error',
                  title: 'Erreur',
                  message:
                    error.response.data.message ??
                    'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                })
              }
            })
            .finally(() => {
              this.busy = false
            })
        }
      })
    },
    exportPhotos(project: Project) {
      const inventoryId = project.inventory?.id
      this.$api
        .post(`/inventory/${inventoryId}/export-photos`)
        .then(() => {
          const documentUrl = `${process.env.VUE_APP_API_URL}/document/inventory/${inventoryId}/${this.user.client.id}/download`
          window.open(documentUrl, '_blank')
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
    },
  },
})
